.pricingCard {
    background: #FDFDFD;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    padding: 14% 10% 10%;
}  
.pricingImage{
    width: 100%;
    height: 9vw;
    display: flex;
    justify-content: center;
    margin-bottom: 1.8vw;
    text-align: center;
}
.pricingImage img{
    width: 100%;
    height: 100%;
}
.pricingCategory{
    font-size: 2vw;
    color: var(--primary);
    text-align: left;
}
.hr{
    height: 1px;
    width: 100%;
    display: block;
    background-color: #C5C5C5;
    margin: 1.8vw 0;
}
.featureList h4{
    color: #2A2A2A;
    font-size: 1.25vw;
    line-height: 2.5;
    display: flex;
    gap: 1vw;
}
.pricingPrice{
    color: #1a1a1a;
    font-size: 1.45vw;
    font-weight: 600;
    line-height: 1;
}
.pricingType{
    font-weight: 400;
    color: #999999;
    font-size: 18px;
}
.pricingLink{
    margin-top: 1.5vw;
}
  
.pricingSelectors{
    margin-bottom: 2.5vw;
}
  
.pricingSelectorType{
    font-size: 1.2vw;
    color: #A9A9A9;
    transition: 0.3s;
}
.pricingSelectorType.active{
    color: black;
}
.pricingToggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}


/* Features */
.wrapper{
    width: 100%;
}
.featureItem{
    width: 100%;
    padding: 2vw 20px;
    position: relative;
    cursor: pointer;
  }
  .featureItem .featureItemContent{
    width: 85%;
  }
  .featureItem .featureItemContent.features{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.5vw;
  }
  .featureMainContent{
    width: 90%;
  }
  .content{
    margin: 1vw 0;
  }
  .content span{
    display: block;
    margin: 0.3vw 0;
    color: #2A2A2A;
    font-size: 1.25vw;
  }
  .content span::before{
    content: "•";
    margin-right: 0.8vw;
    margin-left: 10px;
  }
  .contentHead{
    font-size: 2.5vw;
    font-weight: 500;
    color: #616161;
    padding-bottom: 10px;
  }
  .featureItem .featureImg{
    position: absolute;
    right: 3vw;
    top: 2.5vw;
  }
  .featureItem .featureImg img{
    width: 3.5vw;
    transition: 0.3s;
  }


/* =======================900px Start============================== */
  /* Price Card */
@media screen and (max-width: 900px) {
  .pricingCard{
    margin-bottom: 10vw;
  }
  .pricingImage{
    height: 35vw;
    margin-bottom: 6vw;
  }
  .featureList h4{
    font-size: 4.5vw;
    gap: 2vw;
  }

  .pricingCategory{
    font-size: 7vw;
    margin-bottom: 4vw;
  }
  .pricingPrice{
    font-size: 5vw;
  }
  .pricingType{
    font-size: 3.5vw;
  }

  /* Features */
  .featureItem{
    padding: 4vw;
  }
  .contentHead{
    font-size: 5vw;
  }
  .featureItem .featureImg {
    top: 5vw;
  }
  .featureItem .featureImg img{
    width: 8vw;
  }
  .content span{
    font-size: 3.5vw;
    margin: 2vw 0;
  }
  .content span::before{
    margin-right: 2vw;
    margin-left: 2vw;
  }
}
/* =======================900px End============================== */

/* =======================640px Start============================== */
/* =======================640px End============================== */
